<template>
  <div class="wrap x-f" id="wrap" v-loading="loading">
    <!-- 商品数据左边盒子 -->
    <categoryTreeCard categoryTreeTitle="配方商品类别" @treeNodeId="getTreeNodeId" @isHalfGoods="getIsHalfGoods"
      :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight" :isNoProduct="true"
      :categoryTreeOptions="categoryTreeOptions" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" @isShowHighCom="getIsShowHigh"
              :dropDownList="dropDownList" :isBillDate="false" :isSearchInput="true"
              isSearchInputTitle="输入配方编号、商品编码、名称查询" />
            <div v-show="showHigh" class="x-f marT10">
              <!-- 第一行 -->
              <!-- 是否启用 -->
              <div class="marR15">
                <span class="fS14MR10">启用状态</span>
                <el-select size="mini" class="inputWidth" v-model="queryParams.isStop" placeholder="全部" filterable
                  clearable>
                  <el-option label="停用" :value="true"></el-option>
                  <el-option label="启用" :value="false"></el-option>
                </el-select>
              </div>
              <!-- 单据状态 -->
              <div>
                <span class="fS14MR10">单据状态</span>
                <el-select size="mini" class="inputWidth" v-model="queryParams.bomStatus" placeholder="全部" filterable
                  clearable>
                  <el-option label="未审核" value="0"></el-option>
                  <el-option label="已审核" value="2"></el-option>
                  <el-option label="已关闭" value="4"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="单据信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isAuditBtn="true" :multiple="multiple" @handleAudit="auditBill('审核')"
              @handleRedoAudit="auditBill('反审核')" @handleAdd="handleAdd" @handleDelete="auditBill('删除')"
              :isExportBtn="true" @handleExport="handleExport" :isImportBtn="true" @handleImport="openToChannel = true">
              <template slot="specialDiyBtn">
                <el-dropdown class="marR10" split-button type="warning" icon="el-icon-circle-check" :disabled="multiple"
                  @click="getEnable(0)" @command="getEnable(1)" size="mini">
                  启用
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="禁用">禁用</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button style="color: #fff; background-color: #12cc9f" icon="el-icon-document-copy"
                  :disabled="multiple" @click="getCopyOrder" size="mini">复制
                </el-button>
                <el-button type="primary" icon="el-icon-arrow-down" @click="arrowDownUp" size="mini">全部展开/收缩
                </el-button>
              </template>
            </operatingButton>

            <div>
              <el-table v-loading="loadingTable" tooltip-effect="dark" :data="tableData"
                @selection-change="handleSelectionChange" border ref="multipleTable" @row-click="handleRowClick"
                :max-height="tableHeight" :height="tableHeight"
                :tree-props="{children: 'childList', hasChildren: 'hasChildren'}" row-key="bomId">
                <el-table-column type="selection" width="50" align="center" :selectable="disableCheckbox" />
                <el-table-column label="序号" align="center" width="80" prop="parentIndex" type='' />
                <el-table-column show-overflow-tooltip label="配方编号" align="center" prop="bomNo" min-width="200"
                  key="bomNo">
                  <template slot-scope="scope">
                    <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                      {{ scope.row.bomNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="180" />
                <el-table-column label="商品名称" align="center" prop="goodsName" min-width="160" />
                <el-table-column label="条码" align="center" prop="barcode" min-width="180" />
                <el-table-column label="单位" align="center" prop="unitName" min-width="100" />
                <el-table-column label="商品类别" align="center" prop="categoryName" min-width="160" />
                <el-table-column label="单个用量" align="center" prop="oneUseQty" min-width="100" />
                <el-table-column label="批产量" align="center" prop="batchProdQty" min-width="100" />
                <el-table-column label="成本" align="center" prop="oneCostMoney" min-width="100" />
                <el-table-column label="批量成本" align="center" prop="batchCostMoney" min-width="100" />
                <el-table-column label="损耗率(%)" align="center" prop="loseRate" min-width="100" />
                <el-table-column label="启用状态" align="center" prop="isStopName" min-width="100">
                  <template slot-scope="scope">
                    {{ scope.row.isStop == true ? '停用' : '启用' }}
                  </template>
                </el-table-column>
                <el-table-column label="单据状态" align="center" prop="bomStatusName" min-width="120" />
                <el-table-column label="版本号" align="center" prop="bomVer" min-width="120" />
                <el-table-column label="备注" align="center" prop="remark" min-width="155" />
                <el-table-column label="审核人" align="center" prop="auditBy" min-width="120" />
                <el-table-column label="审核时间" align="center" prop="auditTime" min-width="155" />
                <el-table-column label="修改人" align="center" prop="updateBy" min-width="120" />
                <el-table-column label="修改时间" align="center" prop="updateTime" min-width="155" />
                <el-table-column label="创建人" align="center" prop="createBy" min-width="120" />
                <el-table-column label="创建时间" align="center" prop="createTime" min-width="155" />
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
     <to-channel :visible.sync="openToChannel" :downloadFile="downloadFile" @upload="uploadFile"></to-channel>
    <importError :errVisible.sync="errVisible" :errorList="errorList" />
  </div>
</template>

<script>
import importError from "@/views/components/importError"; //错误信息
import ToChannel from "@/components/dialogTemplate/toChannel/index.vue";
import { ossExportAPI, ossExportKeyAPI } from "@/api/ossExport";
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import {
  serviceListAPI,
  serviceUpdateStatusAPI,
  serviceTreeAPI,
  serviceCopyAPI,
  serviceIsStopAPI,
  uploadFileRequest
} from '@/api/produce/bom/service' //生产配方接口
export default {
  name: 'storeSetup',
  dicts: ['bill_status'],
  components: {
    categoryTreeCard,
    bottomPage,
    cardTitleCom,
    seniorSearch,
    operatingButton,
    ToChannel,
    importError
  },
  data() {
    return {
       errorList: [],
       errVisible: false,
       downloadFile: {
        // 下载模板
        fileName: "配方导入模板.xlsx", // 文件名
        downloadUrl:"https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-21/隼云配方导入示范文件.xlsx", // 下载地址
      },
      openToChannel: false, // 打开导入弹框
      isExpand: false, //是否展开
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'goodsNos', name: '商品编码' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '商品条码' }
      ],
      categoryTreeOptions: [], //树的数据
      isCheckAll: false, //判断当前是否全选数据
      //高级搜索图标
      icon: 'el-icon-caret-bottom',
      //是否显示高级搜索
      showHigh: false,
      // 遮罩层
      loading: false,
      loadingTable: false, //表格遮罩
      name: [], //选中数组的名称
      ids: [], // 选中数组
      bomStatusZeroId: [], //选中数组的未审核的状态0
      bomStatusTwoId: [], //选中数组的已审核的状态2
      bomStatusThreeId: [], //选中数组的已删除的状态3
      bomStatusIsStopId: [], //筛选出来已审核且停用的单据状态id
      bomStatusIsStopNoId: [], //筛选出来已审核且未停用的单据状态id
      tableData: [], //配方表格
      // 非多个禁用
      multiple: true,
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    //初始化全部的数据
    this.getInitializeDataAll()
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });
  },
  methods: {
    uploadFile(fromData) {
      uploadFileRequest(fromData)
        .then((res) => {
          this.$message.success("上传成功");
          this.openToChannel = false;
          this.getList();
        })
        .catch((error) => {
          this.errorList = error.data.errorList.map((item) => {
            return { errorInfo: item };
          });
          this.errVisible = true;
        });
    },
    disableCheckbox(row) {
      return !row.parentBomId
    },
    arrowDownUp() {
      this.isExpand = !this.isExpand
      this.$nextTick(() => {
        this.handleArr(this.tableData, this.isExpand);
      });

    },
    handleArr(arr, flag) {
      arr.forEach(i => {
        this.$refs.multipleTable.toggleRowExpansion(i, flag);
        if (i.children) {
          this.handleArr(i.children);
        }
      });
    },
    async handleExport() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const res = await ossExportAPI(
          '/api/system/produce/bill/bom/export',
          this.queryParams
        );
        const intervalId = setInterval(async () => {
          const res1 = await ossExportKeyAPI(
            '/api/system/produce/bill/bom/getQueryExport',
            { key: res.data }
          );
          if (res1.data?.accessUrl) {
            clearInterval(intervalId); // 结束循环
            loading.close();
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = res1.data.accessUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }, 1000);
      } catch (error) {
        loading.close();
      }
      // this.download(
      //   "/api/system/produce/bill/bom/export",
      //   {
      //     ...this.queryParams,
      //   },
      //   `生产配方导出.xlsx`
      // );
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (!row.parentBomId) {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    //点击类别树
    async getTreeNodeId(value) {
      this.queryParams.categoryId = value
      await this.getList()
    },
    //勾选半成品
    getIsHalfGoods(value) {
      this.queryParams.isIncluding = value
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        let res = await serviceListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        res.rows = res.rows.map((item, index) => {
          item.parentIndex = index + 1
          if (item.childList) {
            item.childList.forEach((x, inD) => {
              x.parentIndex = item.parentIndex + '.' + (inD + 1)
            })
          }
          return item
        })
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //启用停用
    async getEnable(value) {
      const num = value
      if (num === 0) {
        if (this.bomStatusIsStopId.length === 0) {
          this.$message.error('只能启用已审核且禁用的状态')
          return
        }
        const obj = {
          billIds: this.bomStatusIsStopId,
          isStop: num
        }
        await serviceIsStopAPI(obj, this.urlEnable)
        this.$message({
          message: `启用成功`,
          type: 'success'
        })
      } else {
        if (this.bomStatusIsStopNoId.length === 0) {
          this.$message.error('只能停用 已审核且启用的状态')
          return
        }
        const obj = {
          billIds: this.bomStatusIsStopNoId,
          isStop: num
        }
        await serviceIsStopAPI(obj, this.urlEnable)
        this.$message({
          message: `停用成功`,
          type: 'success'
        })
      }
      this.getList()
    },
    //复制
    async getCopyOrder() {
      let obj = {
        billIds: this.ids
      }
      await serviceCopyAPI(obj)
      this.getList()
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.bomStatusZeroId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            bomIds: this.bomStatusZeroId,
            bomStatus: '2'
          }
          //发送审核api
          await serviceUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '反审核') {
          if (this.bomStatusTwoId.length === 0) {
            this.$message.error('勾选单据中没有已审核的单据哦~')
            return
          }
          const obj = {
            bomIds: this.bomStatusTwoId,
            bomStatus: '0'
          }
          //发送反审核api
          await serviceUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('反审核成功')
        } else if (name === '删除') {
          if (this.bomStatusThreeId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            bomIds: this.bomStatusThreeId,
            bomStatus: '3'
          }
          //发送删除api
          await serviceUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('删除成功')
        }
        await this.getList()
      } catch { }
    },
    //是否显示高级搜索
    showHighSearch() {
      this.icon =
        this.icon === 'el-icon-caret-bottom'
          ? 'el-icon-caret-top'
          : 'el-icon-caret-bottom'
      this.showHigh = !this.showHigh
    },
    //新增单据事件
    handleAdd() {
      this.$router.push({
        name: 'serviceDetail',
        query: {
          type: 'Add'
        }
      })
    },
    //跳转详情
    handleDetail(row) {
      const bomId = row.bomId
      this.$router.push({
        name: 'serviceDetail',
        query: {
          bomId: bomId,
          type: 'Update'
        }
      })
    },

    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true
      let { rows, total } = await serviceListAPI(this.queryParams)
      rows = rows.map((item, index) => {
        item.parentIndex = index + 1
        if (item.childList) {
          item.childList.forEach((x, inD) => {
            x.parentIndex = item.parentIndex + '.' + (inD + 1)
          })
        }
        return item
      })
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //初始化全部的数据
    async getInitializeDataAll() {
      this.loading = true
      //表格数据
      let { rows, total } = await serviceListAPI(this.queryParams)
      rows = rows.map((item, index) => {
        item.parentIndex = index + 1
        if (item.childList) {
          item.childList.forEach((x, inD) => {
            x.parentIndex = item.parentIndex + '.' + (inD + 1)
          })
        }
        return item
      })
      this.tableData = rows
      this.total = total
      //树数据
      this.categoryTreeOptions = await serviceTreeAPI()
      this.loading = false
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.bomId) //单据id
      //筛选出来未审核的单据状态id0
      this.bomStatusZeroId = selection
        .map(item => {
          if (item.bomStatus == '0') {
            return item.bomId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核的单据状态id2
      this.bomStatusTwoId = selection
        .map(item => {
          if (item.bomStatus == '2') {
            return item.bomId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未删除的单据状态id3
      this.bomStatusThreeId = selection
        .map(item => {
          if (item.bomStatus != '3' && item.bomStatus == '0') {
            return item.bomId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核且停用的单据状态id
      this.bomStatusIsStopId = selection
        .map(item => {
          if (item.isStop == true && item.bomStatus == '2') {
            return item.bomId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核且未停用的单据状态id
      this.bomStatusIsStopNoId = selection
        .map(item => {
          if (item.isStop == false && item.bomStatus == '2') {
            return item.bomId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    /** 下载模板操作 */
    importTemplate() {
      this.download(
        'system/user/importTemplate',
        {},
        `user_template_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      margin-bottom: 6px;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
