var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-form", { ref: "form", attrs: { "label-width": "70px" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "wrap",
        attrs: { id: "wrap" },
      },
      [
        _c("topOperatingButton", {
          attrs: {
            isSubmitAddBtn: false,
            isAuditBillBtn: false,
            id: "topOperatingButton",
          },
          on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
        }),
        _c(
          "cardTitleCom",
          { attrs: { cardTitle: "查询", id: "search-card" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "navSearch" },
                [
                  _c(
                    "div",
                    { staticClass: "marB10" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("单据类型"),
                      ]),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isProduce,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isProduce", $$v)
                            },
                            expression: "queryParams.isProduce",
                          },
                        },
                        [_vm._v("生产计划单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isShopDue,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isShopDue", $$v)
                            },
                            expression: "queryParams.isShopDue",
                          },
                        },
                        [_vm._v("门店预订单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isShopMaterialOrder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isShopMaterialOrder",
                                $$v
                              )
                            },
                            expression: "queryParams.isShopMaterialOrder",
                          },
                        },
                        [_vm._v("门店原料订货单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isShopOrder,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isShopOrder", $$v)
                            },
                            expression: "queryParams.isShopOrder",
                          },
                        },
                        [_vm._v("门店订货单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isSell,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isSell", $$v)
                            },
                            expression: "queryParams.isSell",
                          },
                        },
                        [_vm._v("销售订单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isProductStorage,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isProductStorage", $$v)
                            },
                            expression: "queryParams.isProductStorage",
                          },
                        },
                        [_vm._v("产品入库单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isProduceOtherOrder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isProduceOtherOrder",
                                $$v
                              )
                            },
                            expression: "queryParams.isProduceOtherOrder",
                          },
                        },
                        [_vm._v("其他订货单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          model: {
                            value: _vm.queryParams.isProduceOtherMaterialOrder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isProduceOtherMaterialOrder",
                                $$v
                              )
                            },
                            expression:
                              "queryParams.isProduceOtherMaterialOrder",
                          },
                        },
                        [_vm._v("其他原料订货单")]
                      ),
                    ],
                    1
                  ),
                  _c("seniorSearch", {
                    class: { marB10: !_vm.showHigh },
                    attrs: {
                      filterTimeStr: "thisMonth",
                      getList: _vm.getList,
                      refreshSearch: _vm.refreshQueryParams,
                      isInvokeList: false,
                    },
                    on: { isShowHighCom: _vm.getIsShowHigh },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showHigh,
                          expression: "showHigh",
                        },
                      ],
                      staticClass: "x-w marT10",
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "marR10",
                          attrs: { label: "到货日期", prop: "datetimeArrival" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              size: "mini",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.datetimeArrival,
                              callback: function ($$v) {
                                _vm.datetimeArrival = $$v
                              },
                              expression: "datetimeArrival",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "生产班次", prop: "produceOrderIds" },
                        },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceOrder",
                                option: {
                                  option: {
                                    remoteBody: {
                                      produceDeptId:
                                        _vm.queryParams.produceDeptId,
                                    },
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceOrderIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "produceOrderIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.produceOrderIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户", prop: "customerIds" } },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listCustomer" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.customerIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "customerIds", $$v)
                              },
                              expression: "queryParams.customerIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订货仓库", prop: "outStoreIds" } },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listStore",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.inStoreIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "inStoreIds", $$v)
                              },
                              expression: "queryParams.inStoreIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "创建人", prop: "createEmployeeIds" },
                        },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listPurEmployee",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.createEmployeeIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "createEmployeeIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.createEmployeeIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c(
          "cardTitleCom",
          { attrs: { cardTitle: "选择源单" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "tableContent" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                      },
                      on: {
                        "row-click": _vm.handleRowClick,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "单据编号",
                          align: "center",
                          prop: "billNo",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据日期",
                          align: "center",
                          prop: "billDate",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产工厂",
                          align: "center",
                          prop: "deptName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产组",
                          align: "center",
                          prop: "produceGroupName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产班次",
                          align: "center",
                          prop: "produceOrderName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "数量",
                          align: "center",
                          prop: "billQty",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          align: "center",
                          prop: "billMoney",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "订货仓库",
                          align: "center",
                          prop: "inStoreName",
                          width: "126",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "到货日期",
                          align: "center",
                          prop: "arrivalDate",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "客户编号",
                          align: "center",
                          prop: "customerNo",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "客户名称",
                          align: "center",
                          prop: "customerName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          align: "center",
                          prop: "billRemark",
                          width: "155",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      idsCom: _vm.selectionWizard,
                      totalCom: _vm.total,
                      isCheck: _vm.isCheck,
                    },
                    on: { selectAllCom: _vm.selectAll },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }