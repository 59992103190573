<template>
  <!--产品发货单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  storeDistributeListAPI,
  storeDistributeUpdateStatusAPI,
  listImportBillInfo,
} from "@/api/produce/bill/storeDistribute"; //产品分货单
export default {
  name: "StoreDistribute",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "e6e00127-6310-dba9-1790-8452b6dc57c5",
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: storeDistributeListAPI,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            label: "单据编号",
            model: "",
            filter: "billNo",
            seniorSearch: true,
          },
          this.$select({
            key: "bill_status",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listProduceDept",
            option: {
              type: "remote",
              label: "生产工厂",
              filter: "produceDeptIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          // this.$select({
          //   key: 'listStore',
          //   option: {
          //     type: 'remote',
          //     seniorSearch: true,
          //     label: '调出仓库',
          //     option: {
          //       multiple: true
          //     }
          //   }
          // }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "audit",
            label: "分货",
            alertText: "确认要分货选中单据吗？",
            btnType: "",
            type: "success",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
          },
          {
            click: "getRelevantDocuments",
            label: "相关单据",
            type: "primary",
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "billStatusName",
            label: "分货状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billQty",
            label: "数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billMoney",
            label: "金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "printCount",
            label: "打印次数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "分货人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "分货时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改日期",
            minWidth: 155,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "add":
          this.$router.push({
            name: "storeDistributeDetail",
          });
          break;
        case "update":
          this.$router.push({
            name: "storeDistributeDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        case "audit":
          this.options.check.forEach((item) => {
            if (item.billStatus != 3) billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await storeDistributeUpdateStatusAPI({
                billIds,
                billStatus: 2,
              });
              this.$message.success("分货成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.error("请选择未删除的单据");
          }
          break;
        case "del":
          this.options.check.forEach((item) => {
            if (item.billStatus == 0) billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await storeDistributeUpdateStatusAPI({
                billIds,
                billStatus: 3,
              });
              this.$message.success("删除成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.error("请选择未分货的单据");
          }
          break;
        case "getRelevantDocuments":
          if (this.options.check.length != 1) {
            return this.$message.error("请勾选一条数据查询相关单据！");
          }
          const res = await listImportBillInfo(this.options.check[0].billId);
          let data = res.data || [];
          this.$router.push({
            name: "produceStoreDistributeRelevant",
            query: {
              type: "produceStoreDistribute",
              data: JSON.stringify(data),
            },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
